<style lang="scss" scoped>
.box {
  position: fixed;
  right: 16px;
  top: 128px;

  z-index: 2;

  .box-main {
    width: 0px;
    transition: 0.5s all;
    background: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding-bottom: 16px;
    overflow: hidden;

    .main {
      width: 434px;
      padding-left: 16px;
      padding-right: 16px;
      overflow: hidden;
    }
  }
}

.box-help {
  width: 22px;
  height: 56px;
  position: absolute;
  left: -22px;
  top: 12px;
  background-image: url("../../../../assets/features/hidden.svg");
  cursor: pointer;

  &#show {
    background-image: url("../../../../assets/features/show.svg");
  }
}

#showBox {
  width: 434px;
}

.header {
  width: 100%;
  display: flex;
  position: relative;
  height: 52px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .header-item {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;
    cursor: pointer;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);

    div {
      width: 100%;
      text-align: center;
    }

    .line {
      width: 100%;
      height: 2px;
      background: #e08c12;
      position: absolute;
      bottom: 0;
      left: 0;
      display: none;
    }

    &#active {
      .line {
        display: block;
      }

      font-weight: 16px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  .header-item:nth-child(1) {
    width: 128px;
  }

  .header-item:nth-child(2) {
    width: 156px;
  }
}

.table {
  margin-top: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 144px - 24px - 70px - 54px);
  overflow-y: auto;
  overflow-x: hidden;
  .table-item:nth-child(odd) {
    background: rgba(240, 240, 240, 1);
  }

  .table-item {
    width: 100%;
    display: flex;
    padding-top: 4.5px;
    padding-bottom: 4.5px;
    position: relative;
    overflow: hidden;
    .spec {
      position: absolute;
      left: 0;
      top: 0;
    }

    .row {
      width: 55%;
      padding-right: 12px;
    }
    .rows {
      padding-right: 12px;
      // width: 45%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding-left: 12px;
      font-size: 13px;
      overflow: hidden;
      white-space: wrap;
      // padding-right: 16px;
      word-break: break-all;
      span {
        white-space: wrap;
      }
    }
    .row {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding-left: 12px;
      font-size: 13px;
      overflow: hidden;
      white-space: wrap;
      // padding-right: 16px;
      word-break: break-all;
    }
    .row-right {
      // width: 51%;
      width: 189px;
    }
    .row-left {
      // width: 49%;
      width: 213px;
    }
    // .row:nth-child(2) {
    //   // word-break: break-all;
    //   // color: rgba(0, 0, 0, 0.8);
    // }

    .rows {
      flex: 1;
      span {
        width: 100%;
      }
      color: rgb(0, 0, 0, 0.6);
    }
  }
}

.showSize {
  cursor: pointer;
  right: 114px;
  bottom: 40px;
  width: 32px;
  height: 32px;
  position: fixed;
  z-index: 3;
}

#unShowSize {
  background-image: url("../../../../assets/unShowSize.svg");
}

#showSize {
  background-image: url("../../../../assets/showSize.svg");
}

#left0 {
  right: 0;
}

.swiper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  .left {
    width: 24px;
    height: 24px;
    background-image: url("./left.svg");
    background-size: 100% 100%;
    margin-right: 24px;
    cursor: pointer;
  }

  .right {
    margin-left: 24px;
    width: 24px;
    height: 24px;
    background-image: url("./left.svg");
    background-size: 100% 100%;
    transform: rotate(180deg);
    cursor: pointer;
  }
  .p {
    display: flex;
    align-items: center;
    gap: 5px;
    .point {
      width: 6px;
      height: 6px;
      cursor: pointer;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.2);

      &.active {
        background: rgba(224, 140, 18, 1);
      }
    }
  }
}

.spec {
  .table-item:nth-child(1) {
    background: linear-gradient(90deg, #e08c12 0%, #f37500 100%), #e08c12;
    > div {
      color: white;
    }
    .row-right {
      // text-align: center;
      display: block;
    }
  }
}

.spec-row {
  animation-name: SSa;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  transform: translateX(100%);
}

@keyframes SSa {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
</style>
<template>
  <div class="feature">
    <div
      class="showSize"
      @click="showSize"
      :id="isShowSize ? 'unShowSize' : 'showSize'"
    ></div>
    <div class="box" :id="showBox ? '' : 'left0'">
      <div
        class="box-help"
        @click="showBox = !showBox"
        :id="showBox ? '' : 'show'"
      ></div>
      <div class="box-main" :id="showBox ? 'showBox' : ''">
        <div class="main">
          <div class="header">
            <div
              v-show="showSpec"
              class="header-item"
              :id="select == 1 ? 'active' : ''"
              @click="select = 1"
            >
              <div>Specifications</div>
              <div class="line"></div>
            </div>
            <div
              class="header-item"
              :id="select == 2 ? 'active' : ''"
              @click="select = 2"
            >
              <div>Basic parameters</div>
              <div class="line"></div>
            </div>
          </div>

          <div class="table" v-if="select == 1">
            <div class="table-item" v-for="item of tableData1">
              <img
                src="./spec.png"
                v-show="item[2] == true"
                class="spec"
                alt=""
              />
              <div class="row" style="white-space: nowrap">
                {{ item[0] }}
              </div>
              <div class="rows" v-if="item[1] && item[1] !== true">
                <span>
                  {{ item[1] }}
                </span>
              </div>
              <div class="rows" v-if="item[2] && item[2] !== true">
                <span>
                  {{ item[2] }}
                </span>
              </div>
              <div class="rows" v-if="item[3] && item[3] !== true">
                <span>
                  {{ item[3] }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="table"
            v-else-if="
              select == 2 &&
              $store.state.meshType !== 'TOPCON' &&
           $store.state.meshType !== 'Three Phase'&&
           $store.state.meshType !== 'Single Phase'
            "
          >
            <div class="table-item" v-for="item of tableData2">
              <div class="row row-left">{{ item[0] }}</div>
              <div class="row rows row-right">{{ item[1] }}</div>
            </div>
          </div>
          <div class="other" v-else>
            <div class="table spec">
              <div class="table-item" v-for="item of tableData4">
                <div class="row row-left">{{ item[0] }}</div>
                <div class="row rows row-right spec-row" v-if="isShowUi">
                  {{ item[1] }}
                </div>
                <div class="row rows row-right" v-if="item[2]">
                  {{ item[2] }}
                </div>
              </div>
            </div>
            <div class="swiper" v-if="tableData3.length != 1">
              <div class="left" @click="addSelectIndex(false)"></div>
              <div class="p">
                <div
                  class="point"
                  v-for="(item, index) of tableData3"
                  :class="{ active: index == selectIndex }"
                  @click="selectIndex = index"
                ></div>
              </div>
              <div class="right" @click="addSelectIndex(true)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../../lib/bus";

export default {
  data() {
    return {
      isShowSize: false,
      select: 1,
      showBox: true,
      showSpec: true,
      isShowUi: true,
      selectIndex: 0,
    };
  },
  watch: {},
  computed: {
    tableData1() {
      let e = {
        LFP_9kWhHV: [
          "LFP 9kWh/HV",
          "8.64kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "3",
          "172.8V",
          "135-197.1V",
          "50 / 25A",
          "197.1V",
          "102kg",
          "670*330*697",
          "CE,UN38.3, IEC62619, IEC63056, IEC62477",
        ],
        LFP_12kWhHV: [
          "LFP 12kWh/HV",
          "11.52kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "4",
          "230.4V",
          "180-262.8V",
          "50 / 25A",
          "262.8V",
          "131kg",
          "670*330*855",
          "CE,UN38.3, IEC62619, IEC63056, IEC62477",
        ],

        LFP_15kWhHV: [
          "LFP 15kWh/HV",
          "14.40kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "5",
          "288V",
          "225-328.5V",
          "50 / 25A",
          "328.5V",
          "160kg",
          "670*330*1013",
          "CE,UN38.3, IEC62619, IEC63056, IEC62477",
        ],

        LFP_18kWhHV: [
          "LFP 18kWh/HV",
          "17.28kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "6",
          "345.6V",
          "270-394.2V",
          "50 / 25A",
          "394.2V",
          "189kg",
          "670*330*1171",
          "CE,UN38.3,IEC62619, IEC63056, IEC62477",
        ],

        LFP_21kWhHV: [
          "LFP 21kWh/HV",
          "20.16kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "7",
          "403.2V",
          "315-459.9V",
          "50 / 25A",
          "459.9V",
          "218kg",
          "670*330*1329",
          "CE,UN38.3,IEC62619, IEC63056, IEC62477",
        ],
        LFP_24kWhHV: [
          "LFP 24kWh/HV",
          "23.04kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "8",
          "460.6V",
          "360-525.6V",
          "50 / 25A",
          "525.6V",
          "247kg",
          "670*330*1487",
          "CE,UN38.3,IEC62619, IEC63056, IEC62477",
        ],
        LFP_27kWhHV: [
          "LFP 27kWh/HV",
          "25.92kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "9",
          "518.4V",
          "405-591.3V",
          "50 / 25A",
          "591.3V",
          "276kg",
          "670*330*1645",
          "CE,UN38.3,IEC62619, IEC63056, IEC62477",
        ],

        LFP_30kWhHV: [
          "LFP 30kWh/HV",
          "28.80kWh",
          "LFP 3000 / HV : 57.6V 50Ah",
          "10",
          "576V",
          "450-657V",
          "50 / 25A",
          "657V",
          "305kg",
          "670*330*1803",
          "CE,UN38.3,IEC62619, IEC63056, IEC62477",
        ],
        LFP_5kWhLV: [
          "LFP 5kWh/LV",
          "5.12kWh",
          "4.6kWh",
          "2.5kW",
          "2.9kWh for 3 seconds",
          "50A",
          "43.2~58.4Vd.c",
          "51.2Vd.c",
          "60A",
          "58.4Vd.c",
          "58kg",
          "490(550)*650(700)*196(216)mm",
          "CE,UN38.3, IEC62619, IEC63056",
        ],
        LFP_10kWhLV: [
          "LFP 10kWh/LV",
          "10kWh",
          "9.2kWh",
          "5kW",
          "5.8kWh for 3 seconds",
          "100A",
          "43.2~58.4Vd.c",
          "51.2Vd.c",
          "100A",
          "58.4Vd.c",
          "109KG",
          "585(645)*940(995)*205(225)mm",
          "CE,UN38.3, IEC62619, IEC63056",
        ],
        LFP_5000: [
          "LFP 5000",
          "5.12kWh",
          "4.6kWh",
          "2.5kW",
          "2.9kWh for 3 seconds",
          "50A",
          "43.2~58.4Vd.c",
          "51.2Vd.c",
          "50A",
          "50A",
          "50A",
          "58.4V d.c",
          "49.5kg",
          "453×433×177mm",
          "CE,UN38.3, IEC62619, IEC63056",
        ],
      };

      let res = e[this.$store.state.mesh];
      let data = [];

      if (this.$store.state.meshType == "LFP9-30kWh/HV") {
        data = [
          ["Usable Energy*", res[1], true],
          ["Battery Module", res[2], false],
          ["Number of Modules", res[3], true],
          ["Nominal Voltage", res[4], true],
          ["Operating Voltage Ranges", res[5], true],
          ["Nominal Dis-/Charge Current", res[6], false],
          ["Max. Charge Voltage", res[7], true],
          ["Weight", res[8], true],
          ["Dimension(mm)", res[9], true],
          ["Safety", res[10], false],
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          // ["Module Type*", res[0], false],
          ["Total Energy*", res[1], false],
          ["Usable Energy(DC)*", res[2], false],
          ["Nominal Dis-/Charge Power", res[3], false],
          ["Peak Power(Only Discharge)", res[4], false],
          ["Constant Current(Only Discharge)", res[5], true],
          ["Voltage", res[6], true],
          ["Nominal Voltage", res[7], true],
          // ["Nominal Current", res[8], false],
          ["Max. Charge Voltage", res[9], true],
          ["Weight", res[10], false],
          ["Dimension(mm)", res[11], false],
          ["Safety", res[12], true],
        ];
      } else if (
        this.$store.state.meshType == "Three Phase" ||
        this.$store.state.meshType == "Single Phase"
      ) {
        if (this.$store.state.mesh == "Single-Phase") {
          data = [
            ["Dimension (W/H/D) (mm)", res[0]],
            ["Weight", res[1]],
            ["Operation Temperature Range", res[2]],
            ["Cooling ", res[3]],
            ["Noise", res[4]],
            ["Communication with BMS", res[5]],
            ["Protection Degree", res[6]],
            ["Installation Style", res[7]],
            ["Warranty", res[8]],
            ["Certificates and Approvals", res[9]],
          ];
        } else {
          data = [
            ["", res[0]],
            ["", res[1]],
            ["", res[2]],
            ["", res[3]],
            ["", res[4]],
            ["", res[5]],
            ["", res[6]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
            ["", res[0]],
          ];
        }
      } else if (this.$store.state.meshType == "TOPCON") {
        data = [
          ["External Dimensions", "1722x1134x30mm"],
          ["Weight", "23.4kg"],
          ["Solar Cells", "N-Type 16BB 182mm (2x54pcs)"],
          ["Front Glass", "AR Coated 2+2 mm tempered glass"],
          ["Frame", "Anodized aluminum alloy"],
          ["Junction Box", "IP68"],
          [
            "Output Cables",
            "4.0mm², 1200mm (+),1200mm (-), length can be customized",
          ],
          ["Connector", "MC4 Compatible"],
          ["Mechanical Load", "Front Side Max. 5400Pa, Rear Side Max. 2400Pa"],
          ["Pmax Temperature Coefficient", "-0.290%/℃"],
          ["Voc Temperature Coefficient", "0.250%/℃"],
          ["Isc Temperature Coefficient", "+0.045%/℃"],
          ["Operating Temperature", "-40~+85℃"],
          ["Nominal Operating Cell Temperature(NOCT)", "45±2℃"],
          ["Dimension(mm)", "670*330*697"],
          ["Power Gain", "5%", "15%", "25%"],
          ["Maximum Power- Pmax(W)", "441.0", "483.0", "525.0"],
          ["Open Circuit Voltage - Voc(V)", "38.50", "38.50", "38.60"],
          ["Voltage at Pmax -Vmp(V)", "32.00", "32.00", "32.10"],
          ["Current at Pmax - Imp(A)", "13.80", "15.11", "16.43"],
          ["External Dimensions", "1722x1134x30mm"],
          ["Container", "20’GP", "40’HQ"],
          ["Pieces per Pallet", "36", "36"],
          ["Pallets per Container", "6", "26"],
          ["Pieces per Container", "216", "936"],
        ];
      } else {
        data = [
          // ["Module Type*", res[0], false],
          ["Total Energy*", res[1], false],
          ["Usable Energy(DC)*", res[2], false],
          ["Nominal Dis-/Charge Power", res[3], false],
          ["Peak Power(Only Discharge)", res[4], false],
          ["Constant Current(Only Discharge)", res[5], false],
          ["Voltage", res[6], false],
          ["Nominal Voltage", res[7], false],
          ["Nominal Current", res[8], false],
          ["Max. Charge Current", res[9], false],
          ["Max. Discharge Current", res[10], false],
          ["Max. Charge Voltage", res[11], false],
          ["Weight", res[12], false],
          ["Dimension(mm)", res[13], false],
          ["Safety", res[14], false],
        ];
      }

      return data;
    },
    tableData2() {
      let data = [];

      if (this.$store.state.meshType == "LFP9-30kWh/HV") {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor or outdoor"],
          ["Operating Charge", "0~55℃"],
          ["Temperature Discharge", "-20~55℃"],
          ["Humidity", "0~95% (No condensed water)"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Naturl cooling"],
          ["Case Material", "Metal+Plastic"],
          ["IP Rating", "IP 54"],
          ["Protective Class", "I"],
          ["Warranty", "10 years"],
          ["Life Span", "＞15 years"],
          ["Communication", "CAN/WIFI"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short-circuit/Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor or outdoor"],
          ["Operating Charge", "From 0~45℃"],
          ["Temperature Discharge", "From -10~50℃"],
          ["Humidity", "4~100%（No condensed water）"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Natural cooling"],
          ["Case Material", "Metal+Plastic"],
          ["IP rating", "IP 65"],
          ["Protective Class", "I"],
          ["Max. Number of Parallel", "16"],
          ["Warranty", "10 years"],
          ["Life Span", ">15 years"],
          ["Communication", "CAN/RS485"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short circuit/ Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      } else if (this.$store.state.meshType == "SKT Series") {
        console.log("?");
      } else if (this.$store.state.meshType == "TOPCON") {
        data = [];
      } else {
        data = [
          ["Max.recommended DOD", "90%"],
          ["Operating Condition", "Indoor"],
          ["Operating Charge", "From 0~45℃"],
          ["Temperature Discharge", "From -10~50℃"],
          ["Storage Temperature", "From -20~50℃"],
          ["Humidity", "4~100% (No condensed water)"],
          ["Pollution Degree", "3"],
          ["Over Voltage Category", "II"],
          ["Cooling Type", "Natural cooling"],
          ["Case Material", "Metal"],
          ["IP rating", "IP20"],
          ["Protective Class", "I"],
          ["Max. Number of Parallel", "16"],
          ["Warranty", "10 years"],
          ["Life Span", ">15 years"],
          ["Communication", "CAN/RS485"],
          ["Protection Mode", "Triple hardware protection"],
          [
            "Battery Protection",
            "Over-current/Over-voltage/Short circuit/ Under-voltage",
          ],
          ["Hazardous Material Classification", "9"],
        ];
      }

      return data;
    },
    tableData3() {
      let data = {
        "SKT410-430M10-108D4": [
          [
            [1, 2],
            [3, 4],
            [1, 2],
            [3, 4],
            [1, 2],
            [3, 4],
          ],
          [
            [1, 2],
            [3, 4],
            [1, 2],
            [3, 4],
            [1, 2],
            [3, 4],
          ],
        ],
        "Single-Phase": [
          [
            ["Model", "SKT3KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "80A/80A"],
            ["Rated AC Output Active Power", "3000W"],
            ["Maximun AC Output Active Power", "3300W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "15A"],
            ["Maximun Continuous", "35A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.50%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
          [
            ["Model", "SKT4KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "100A/100A"],
            ["Rated AC Output Active Power", "4000W"],
            ["Maximun AC Output Active Power", "4400W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "20A"],
            ["Maximun Continuous", "35A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.50%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
          [
            ["Model", "SKT5KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "120A/120A"],
            ["Rated AC Output Active Power", "5000W"],
            ["Maximun AC Output Active Power", "5500W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "25A"],
            ["Maximun Continuous", "35A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.50%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
          [
            ["Model", "SKT6KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "150A/150A"],
            ["Rated AC Output Active Power", "6000W"],
            ["Maximun AC Output Active Power", "6600W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "28.8A"],
            ["Maximun Continuous", "50A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.60%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
          [
            ["Model", "SKT8KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "200A/200A"],
            ["Rated AC Output Active Power", "8000W"],
            ["Maximun AC Output Active Power", "8800W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "38.5A"],
            ["Maximun Continuous", "50A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.60%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
          [
            ["Model", "SKT10KTL"],
            ["Battery Type", "Lead-acid or Li-ion"],
            ["Battery voltage range", "40-60V"],
            ["Battery Maximum charge/discharge current", "210A/210A"],
            ["Rated AC Output Active Power", "10000W"],
            ["Maximun AC Output Active Power", "10500W"],
            ["Peak Power (off grid)", "2 time of rated power, 10s"],
            ["Maximun AC Output Current", "45.6A"],
            ["Maximun Continuous", "50A"],
            ["Charging Stratege for Li-lon Batteries", "Self-adaption to BMS"],
            ["Power factor", "1"],
            ["Euro Eficiency", "96.60%"],
            ["Noise", "≤30dB"],
            ["BMS communication", "RS485, CAN"],
          ],
        ],
        "Three-Phase": [
          [
            ["Model", "SKT4KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "4000VA"],
            ["Max AC apparent power", "5000VA"],
            ["Max AC output current", "8A"],
            ["Three phase unbalance output", "0~100%"],
            ["Back-up Max AC apparent power", "4000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.3%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
          [
            ["Model", "SKT5KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "5000VA"],
            ["Max AC apparent power", "5500VA"],
            ["Max AC output current", "10A"],
            ["Three phase unbalance output", "0~100%"],
            ["Back-up Max AC apparent power", "5000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.3%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
          [
            ["Model", "SKT6KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "6000VA"],
            ["Max AC apparent power", "7000VA"],
            ["Max AC output current", "12A"],
            ["Three phase unbalance output", "0~100%"],
            ["Back-up Max AC apparent power", "6000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.5%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
          [
            ["Model", "SKT8KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "8000VA"],
            ["Max AC apparent power", "8800VA"],
            ["Max AC output current", "15A"],
            ["Three phase unbalance output", "0~100%"],
            ["Back-up Max AC apparent power", "8000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.5%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
          [
            ["Model", "SKT10KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "10000VA"],
            ["Max AC apparent power", "11000VA"],
            ["Max AC output current", "17A"],
            ["Three phase unbalance output", "0~100%"],
            ["Back-up Max AC apparent power", "10000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.5%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
          [
           ["Model", "SKT12KTL3H"],
            ["Battery Type", "Li-Lon"],
            ["Battery voltage range", "130~700V"],
            ["Battery Maximum charge/discharge current", "25/25A"],
            ["AC nominal power", "12000VA"],
            ["Max AC apparent power", "13200VA"],
            ["Max AC output current", "20A"],
            ["Three phase unbalance output", "0~80%"],
            ["Back-up Max AC apparent power", "10000VA"],
            ["Output THDV(@Liuear Load)", "<3%"],
            ["European efficiency", "97.5%"],
            ["Power factor", "0.8leading...0.8laging"],
            ["Display", "LCD"],
            ["BMS communication", "CAN/RS485"],
          ],
        ],
        "TOPCon-108cells": [
          [
            ["Model", "SKT430M10-108D6"],
            ["Front Side", "STC", "NOCT"],
            ["Maximum Power- Pmax(W)", "430", "328.7"],
            ["Open Circuit Voltage - Voc(V)", "38.72", "36.80"],
            ["Short- Circuit Current - Isc(A)", "14.25", "11.49"],
            ["Voltage at Pmax -Vmp(V)", "32.34", "30.19"],
            ["Current at Pmax - Imp(A)", "13.30", "10.89"],
            ["Module Efficiency -ηm(%)", "22.00", "-"],
            ["Power Tolerance(W)", "（0，+4.99W）"],
            ["Maximum System Voltage(V)", "1500Vdc (IEC / UL)"],
            ["Maximum Series Fuse Rating (A)", "30A"],
            ["External Dimensions", "1722x1134x30mm"],
            ["Weight", "21kg"],
            ["Solar Cells", "N-Type 16BB 182mm (2x54pcs)"],
            ["Front Glass", "AR Coated 1.6+1.6 mm tempered glass"],
            ["Frame", "Anodized aluminum alloy"],
            ["Junction Box", "IP68"],
            ["Connector", "MC4 Compatible"],
            [
              "Mechanical Load",
              "Front Side Max. 5400Pa, Rear Side Max. 2400Pa",
            ],
            ["Pieces per Pallet", "36pcs / pallet"],
            ["Pallets per Container", "6pallets / 20’GP ;\n26pallets / 40’HQ"],
            ["Pieces per Container", "216pcs / 20’GP ;\n936pcs / 40’HQ"],
          [  'Certificates','TUV、CE、UL、MCS、UKCA']
          ],
        ],
        "TOPCon-144cells": [
          [
            ["Model", "SKT580M10-144D5"],
            ["Front Side", "STC", "NOCT"],
            ["Maximum Power- Pmax(W)", "580", "436"],
            ["Open Circuit Voltage - Voc(V)", "51.47", "48.89"],
            ["Short- Circuit Current - Isc(A)", "14.37", "11.60"],
            ["Voltage at Pmax -Vmp(V)", "42.60", "39.86"],
            ["Current at Pmax - Imp(A)", "13.62", "10.94"],
            ["Module Efficiency -ηm(%)", "22.45", "-"],
            ["Power Tolerance(W)", "（0，+4.99W）"],
            ["Maximum System Voltage(V)", "1500Vdc (IEC / UL)"],
            ["Maximum Series Fuse Rating (A)", "30A"],
            ["External Dimensions", "2278x1134x35mm"],
            ["Weight", "32kg"],
            ["Solar Cells", "N-Type 16BB 182mm (2x72pcs)"],
            ["Front Glass", "AR Coated 2+2 mm tempered glass"],
            ["Frame", "Anodized aluminum alloy"],
            ["Junction Box", "IP68"],
            ["Connector", "MC4 Compatible"],
            [
              "Mechanical Load",
              "Front Side Max. 5400Pa, Rear Side Max. 2400Pa",
            ],
            ["Pieces per Pallet", "31pcs / pallet"],
            ["Pallets per Container", "4pallets / 20’GP ;\n20pallets / 40’HQ"],
            ["Pieces per Container", "124pcs / 20’GP ;\n620pcs / 40’HQ"],
               [  'Certificates','TUV、CE、UL、MCS、UKCA']
          ],
        ],
      };
      console.log(this.$store.state.mesh)
      return data[this.$store.state.mesh];
    },
    tableData4() {
      return this.tableData3[this.selectIndex];
    },
  },
  watch: {
    selectIndex() {
      this.isShowUi = false;
      setTimeout(() => {
        this.isShowUi = true;
      }, 100);
    },
    "$store.state.mesh"() {
      this.selectIndex = 0;
      this.isShowSize = false;
      bus.$emit("hiddenSize");

      if (
      this.$store.state.meshType == "Three Phase" ||
      this.$store.state.meshType == "Single Phase"  ||
        this.$store.state.meshType == "TOPCON"
      ) {
        this.showSpec = false;
        this.select = 2;
      } else {
        this.showSpec = true;
        this.select = 1;
      }
    },
  },
  methods: {
    addSelectIndex(b) {
      if (b) {
        if (this.selectIndex == this.tableData3.length - 1) {
          this.selectIndex = 0;
        } else {
          this.selectIndex++;
        }
      } else {
        if (this.selectIndex == 0) {
          this.selectIndex = this.tableData3.length - 1;
        } else {
          this.selectIndex--;
        }
      }
    },
    showSize() {
      this.isShowSize = !this.isShowSize;
      if (this.isShowSize) {
        bus.$emit("showSize", this.$store.state.mesh);
      } else {
        bus.$emit("hiddenSize", this.$store.state.mesh);
      }
    },
  },
  created() {
    if (
      this.$store.state.meshType == "Three Phase" ||
      this.$store.state.meshType == "Single Phase" ||
      this.$store.state.meshType == "TOPCON"
    ) {
      this.showSpec = false;
      this.select = 2;
    } else {
      this.showSpec = true;
      this.select = 1;
    }
  },
  destroyed() {
    if (this.isShowSize) {
      bus.$emit("hiddenSize");
    }
  },
};
</script>
